import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getDirectorSliceContent = createAsyncThunk('/director/content', async () => {
    var userData=JSON.parse(localStorage.getItem("userData"));
	const response = await axios.post(`${API_URL}/getFounder`);
	return response;   
})

export const directorSlice = createSlice({
    name: 'director',
    initialState: {
        isLoading: false,
        director : []
    },
    reducers: {
    },
    extraReducers: {
		[getDirectorSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getDirectorSliceContent.fulfilled]: (state, action) => {
			state.director = action.payload.data
			state.isLoading = false
		},
		[getDirectorSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default directorSlice.reducer