import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getContactSliceContent = createAsyncThunk('/contacts/content', async () => {
	const response = await axios.post(`${API_URL}/getContact`);
    console.log(response.data);
	return response;
})

export const contactSlice = createSlice({
    name: 'contacts',
    initialState: {
        isLoading: false,
        contacts : []
    },
    reducers: {
    },
    extraReducers: {
		[getContactSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getContactSliceContent.fulfilled]: (state, action) => {
			state.contacts = action.payload.data
			state.isLoading = false
		},
		[getContactSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default contactSlice.reducer