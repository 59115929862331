import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from "../../../utils/constant";

// Async thunk to fetch result data
export const fetchResults = createAsyncThunk('results/fetchResults', async () => {
  const response = await axios.post(`${API_URL}/getResults`);
  return response.data; // Adjust based on the actual response structure
});

const resultSlice = createSlice({
  name: 'results',
  initialState: {
    isLoading: false,
    results: [],
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchResults.pending]: state => {
      state.isLoading = true;
    },
    [fetchResults.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [fetchResults.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default resultSlice.reducer;
