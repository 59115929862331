import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getWeSupportSliceContent = createAsyncThunk('/wesupports/content', async () => {
    var userData=JSON.parse(localStorage.getItem("userData"));
	const response = await axios.post(`${API_URL}/getWeSupport`, {});
	return response;
})

export const weSupport = createSlice({
    name: 'wesupports',
    initialState: {
        isLoading: false,
        wesupports : []
    },
    reducers: {
    },
    extraReducers: {
		[getWeSupportSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getWeSupportSliceContent.fulfilled]: (state, action) => {
			state.wesupports = action.payload.data
			state.isLoading = false
		},
		[getWeSupportSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default weSupport.reducer