import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from "../../../utils/constant";

export const getAdmissionSliceContent = createAsyncThunk('/admissions/content', async () => {
  const response = await axios.post(`${API_URL}/getAdmissions`);
  return response.data; // Adjust based on the actual response structure
});

const admissionSlice = createSlice({
  name: 'admissions',
  initialState: {
    isLoading: false,
    admissions: [],
  },
  reducers: {},
  extraReducers: {
    [getAdmissionSliceContent.pending]: state => {
      state.isLoading = true;
    },
    [getAdmissionSliceContent.fulfilled]: (state, action) => {
      state.admissions = action.payload;
      state.isLoading = false;
    },
    [getAdmissionSliceContent.rejected]: state => {
      state.isLoading = false;
    },
  },
});

export default admissionSlice.reducer;
