import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getBankSliceContent = createAsyncThunk('/banks/content', async () => {
	const response = await axios.post(`${API_URL}/getBank`);
    console.log(response.data);
	return response;
})

export const bankSlice = createSlice({
    name: 'banks',
    initialState: {
        isLoading: false,
        banks : []
    },
    reducers: {
    },
    extraReducers: {
		[getBankSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getBankSliceContent.fulfilled]: (state, action) => {
			state.banks = action.payload.data
			state.isLoading = false
		},
		[getBankSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default bankSlice.reducer