import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getCourseDetailsSliceContent = createAsyncThunk('/courseDetails/content', async () => {
	const response = await axios.post(`${API_URL}/getCourseDetails`);
    console.log(response.data);
	return response;
})

export const courseDetailsSlice = createSlice({
    name: 'courseDetails',
    initialState: {
        isLoading: false,
        courseDetails : []
    },
    reducers: {
    },
    extraReducers: {
		[getCourseDetailsSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getCourseDetailsSliceContent.fulfilled]: (state, action) => {
			state.courseDetails = action.payload.data
			state.isLoading = false
		},
		[getCourseDetailsSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default courseDetailsSlice.reducer