import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: "Main",
  initialState: {
    userData: {
      name: "",
      emailId: "",
      adminAccess:false,
      profilePic:"",
      mobileNumber:"",
    },
    status: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.status = true;
      state.userData = action.payload;
    },
    setLogout: (state) => {
      state.status = false;
      state.userData = {};
    },
  },
});

export const {setUserData, setLogout,} = userDataSlice.actions;
export default userDataSlice.reducer;
